<template>
    <div class="unsubscribe-sms">
        <FormTemplate
            @submit="validate"
            action="Подтвердить"
        >
            <template v-slot:desc>
                Введите код из смс
            </template>
            <Code
                ref="code"
                type="before"
                :getCode="getCode"
                @ready="submit"
                notInitSend
            />
            <span
                v-if="isFio"
                class="unsubscribe-sms__link"
                @click="sendForm"
            >
                Заполнить форму?
            </span>
        </FormTemplate>
    </div>
</template>

<script>
import sendUnsubscribe from '@/api/sendUnsubscribe'
import Store from '@/store'

import './unsubscribe-sms.scss'

import { mapGetters } from 'vuex'

export default {
    name: 'Before',
    data() {
        return {
            isFio: false,
        }
    },
    computed: {
        ...mapGetters({
            user: ['application/user'],
            code_hash: ['application/code']
        })
    },
    methods: {
        async getCode() {
            await sendUnsubscribe(this.$route.params.data)
        },
        validate() {
            this.$refs.code.validate()

            this.$refs.code.isValid && this.submit()
        },
        async submit() {
            this.isFio = false

            const response = await sendUnsubscribe({
                ...this.$route.params.data,
                code: this.$refs.code.form.code,
                code_hash: this.code_hash,
            })

            this.isFio = response.getFio

            if (response.status === 'wrongCode') {
                this.$refs.code.validate();
                this.$refs.code.formErrors.code = 'Неверный код'
                return
            }

            this.$store.commit('application/clearCode')
            this.$refs.code.resetTimer()

            if (response.success) {
                this.$router.push({
                    name: 'UnsubscribeMessage',
                    params: {
                        data: this.$route.params.data,
                        info: {
                            ...this.$route.params.data,
                            messages: {
                                msg: 'Вы успешно отписаны!'
                            }
                        }
                    }
                })
            }
        },
        sendForm() {
            this.$router.push({
                name: 'UnsubscribeInfo',
                params: {
                    data: this.$route.params.data,
                    info: {
                        ...this.$route.params.data,
                        message: 'Вы ввели неправильный код из СМС. Пожалуйста, заполните форму ниже:'
                    }
                }
            })
        }
    },
    components: {
        FormTemplate: () => import('@/templates/form/Form'),
        Code: () => import('@/components/form/code/Code'),
    },
    async beforeRouteEnter(to, from, next) {
        const {
            info,
            data
        } = to.params

        if (info && data) {
            next()
        } else {
            Store.commit('application/load', false)
            next({
                name: 'Unsubscribe'
            })
        }
    }
}
</script>